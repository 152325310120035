<template>
  <!-- table -->
  <xm-flex>
    <xm-flex-item>
      <xm-total-count>{{`提现列表(${totalCount}条)`}}</xm-total-count>
    </xm-flex-item>
    <xm-flex-item :flex="1">
      <xm-table-plus :query-data="queryData" ref="table">
        <el-table-column prop="id" label="ID" width="80"></el-table-column>
        <el-table-column prop="institutionName" label="小程序"></el-table-column>
        <el-table-column prop="fee" label="提现金额"></el-table-column>
        <el-table-column prop="withdrawRate" label="手续费率"></el-table-column>
        <el-table-column prop="withdrawFee" label="实际提现"></el-table-column>
        <el-table-column prop="userName" label="用户"></el-table-column>
        <el-table-column prop="bankName" label="银行"></el-table-column>
        <el-table-column prop="account" label="账户"></el-table-column>
        <el-table-column label="状态" width="70">
          <template #default="scope">
            <el-tag type="primary" size="small" v-if="scope.row.status === 0">待处理</el-tag>
            <el-tag type="info" size="small" v-else-if="scope.row.status === 1">已通过</el-tag>
            <el-tag type="info" size="small" v-else-if="scope.row.status === 2">已拒绝</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="转账截图" width="70">
          <template #default="scope">
            <el-image
              style="width: 200px; height: 200px;"
              :src="scope.row.transferImage"
              fit="contain"
              v-if="scope.row.transferImage"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="returnReason" label="退回原因"></el-table-column>
        <el-table-column prop="addTime" label="时间" width="150"></el-table-column>
        <el-table-column label="操作" width="120">
          <template #default="scope">
            <el-button
              type="text"
              size="small"
              @click="pass(scope.row)"
              v-if="scope.row.status === 0"
            >通过</el-button>
            <el-button
              type="text"
              size="small"
              @click="reject(scope.row)"
              v-if="scope.row.status === 0"
            >拒绝</el-button>
          </template>
        </el-table-column>
      </xm-table-plus>
    </xm-flex-item>
  </xm-flex>
  <!-- passDialog -->
  <el-dialog v-model="passDialog.visible" title="提现处理" destroy-on-close>
    <el-form ref="bannerRuleForm" :model="passDialog.data" label-width="auto">
      <!-- 图片 -->
      <el-form-item
        label="转账截图"
        :rules="[
        {required:true, message: '转账截图不能为空',trigger: 'blur'}
      ]"
      >
        <el-upload action :http-request="uploadImage" :on-exceed="exceedImage" :limit="1">
          <el-button size="small" type="default">上传图片</el-button>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="passDialog.visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="confirmPass" size="small">确定</el-button>
    </template>
  </el-dialog>
  <!-- rejectDialog -->
  <el-dialog v-model="rejectDialog.visible" title="提现处理" destroy-on-close>
    <el-form ref="rejectRuleForm" :model="rejectDialog.data" label-width="auto">
      <!-- 退回原因 -->
      <el-form-item
        label="退回原因"
        prop="returnReason"
        :rules="[
        {required:true, message: '退回原因不能为空',trigger: 'blur'}
      ]"
      >
        <el-input v-model="rejectDialog.data.returnReason" size="small" maxlength="128" clearable></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="rejectDialog.visible = false" size="small">取消</el-button>
      <el-button type="primary" @click="confirmReject" size="small">确定</el-button>
    </template>
  </el-dialog>
</template>
<script>
import { ElButton, ElDialog, ElTableColumn, ElForm, ElFormItem, ElInput, ElUpload, ElImage, ElTag } from 'element-plus'
import axios from '@/common/utils/axios'
import { onMounted, reactive, ref } from 'vue'
import upload from '@/common/utils/upload'

function table () {
  /**
   * 表单信息
   */
  const table = ref(null)
  const totalCount = ref(0)
  /**
   * 查询表单列表
   */
  const queryData = (page, pageSize) => {
    return new Promise(resolve => {
      axios('/manager/platform/withdraw/record/list', {
        page,
        pageSize
      })
        .then(res => {
          totalCount.value = res.totalCount
          table.value.judgePosition(
            res.platformWithdrawRecordList,
            res.totalCount
          )
          resolve({
            list: res.platformWithdrawRecordList.map(platformWithdrawRecord => {
              return {
                ...platformWithdrawRecord,
                withdrawFee: platformWithdrawRecord.fee - platformWithdrawRecord.fee * platformWithdrawRecord.withdrawRate
              }
            }),
            totalCount: res.totalCount
          })
        })
        .catch(_ => {})
    })
  }
  onMounted(async () => {
    table.value.reload()
  })

  return {
    table,
    queryData,
    totalCount
  }
}

function passDialog () {
  const passDialog = reactive({
    visible: false,
    data: {}
  })
  return {
    passDialog
  }
}

function rejectDialog () {
  const rejectDialog = reactive({
    visible: false,
    data: {}
  })
  return {
    rejectDialog
  }
}

function operation (t, pd, rd) {
  const pass = row => {
    pd.passDialog.visible = true
    pd.passDialog.data = {
      platformWithdrawRecordId: row.id
    }
  }
  const reject = row => {
    rd.rejectDialog.visible = true
    rd.rejectDialog.data = {
      platformWithdrawRecordId: row.id
    }
  }
  const confirmPass = () => {
    axios('/manager/platform/withdraw/record/pass', pd.passDialog.data)
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        pd.passDialog.visible = false
        t.table.value.reloadCurrent()
      }).catch(_ => {})
  }
  const confirmReject = () => {
    axios('/manager/platform/withdraw/record/reject', rd.rejectDialog.data)
      .then(res => {
        window.$message({
          message: '操作成功',
          type: 'success'
        })
        rd.rejectDialog.visible = false
        t.table.value.reloadCurrent()
      }).catch(_ => {})
  }
  /**
   * 上传封面图片
   */
  const uploadImage = ({ file }) => {
    upload(file, '0', url => {
      pd.passDialog.data.transferImage = url
    })
  }
  /**
   * 超出数量提示
   */
  const exceedImage = () => {
    window.$message({
      message: '图片超出数量，请删除后上传',
      type: 'error'
    })
  }
  return {
    reject,
    pass,
    confirmPass,
    confirmReject,
    uploadImage,
    exceedImage
  }
}

export default {
  components: { ElButton, ElDialog, ElTableColumn, ElForm, ElFormItem, ElInput, ElUpload, ElImage, ElTag },

  setup () {
    const t = table()
    const pd = passDialog()
    const rd = rejectDialog()
    const o = operation(t, pd, rd)
    return {
      ...t,
      ...pd,
      ...rd,
      ...o
    }
  }
}
</script>
<style lang="scss" scoped>
.button-group {
  text-align: right;
}
:deep .tox-tinymce {
  height: calc(100% - 20px) !important;
}
.el-dropdown .el-button {
  margin-left: 10px;
}
</style>
